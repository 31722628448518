import { VideoPlayerApp } from "@screencloud/video-player-sdk";
import { CacheManager } from "./CacheManager";
import { VideoPlayer } from "./VideoPlayer";

export class App {
    private readonly cacheManager: CacheManager;
    private readonly videoPlayer: VideoPlayer;

    public constructor(htmlMediaElement: HTMLVideoElement, private readonly app: VideoPlayerApp) {
        this.cacheManager = new CacheManager(app);
        this.videoPlayer = new VideoPlayer(htmlMediaElement, app, this.cacheManager);
    }

    public async run(): Promise<void> {
        await this.cacheManager.init();
        await this.app.connect();
    }

    public async destroy(): Promise<void> {
        await this.videoPlayer.destroy();
        await this.cacheManager.destroy();
    }
}
