import { Cache, CacheAsset, CacheDriver, StorageInfo } from "./types";

const DATABASE_VERSION = 1;

export class IdbCacheDriver<TCacheMetadta, TAssetMetadta> implements CacheDriver<TCacheMetadta, TAssetMetadta> {
    public readonly id = "IdbCacheDriver";

    private db?: IDBDatabase;

    public constructor(public readonly databaseName = "assets") {
    }

    public async init(): Promise<void> {
        this.db = await new Promise<IDBDatabase>((resolve, reject) => {
            const request = indexedDB.open(this.databaseName, DATABASE_VERSION);

            request.onupgradeneeded = function(event) {
                const db: IDBDatabase = this.result;

                if (event.oldVersion === 0) { // create DB
                    const assets = db.createObjectStore("assets", { keyPath: ["id", "cacheId"] });
                    assets.createIndex("cacheId", "cacheId", { multiEntry: true });
                    const assetsData = db.createObjectStore("assetsData", { keyPath: ["id", "cacheId"] });
                    assetsData.createIndex("cacheId", "cacheId", { multiEntry: true });
                    db.createObjectStore("caches", { keyPath: "id" });
                } else {
                    throw new Error(`unknown database version: ${event.oldVersion}`);
                }
            };

            request.onsuccess = (): void => resolve(request.result);
            request.onerror = (event): void => reject(event);
            request.onblocked = (event): void => reject(event);
        });
    }

    public async getStorageInfo(): Promise<StorageInfo> {
        return navigator.storage.estimate();
    }

    public async createCache(cacheId: string, metadata: TCacheMetadta): Promise<void> {
        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction(["caches"], "readwrite");
            transaction.oncomplete = () => resolve();
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            transaction.objectStore("caches").get(cacheId).onsuccess = function(): void {
                if (this.result !== undefined) {
                    reject(new Error("Cache already exists"));
                    return;
                }

                transaction.objectStore("caches").put({
                    id: cacheId,
                    lastAccessed: Date.now(),
                    metadata: metadata,
                    size: 0,
                });
            };
        });
    }

    public async getCache(cacheId: string): Promise<Cache<TCacheMetadta> | undefined> {
        return new Promise((resolve, reject) => {
            let result: Cache<TCacheMetadta> | undefined = undefined;

            const transaction = this.db!.transaction(["caches"]);
            transaction.oncomplete = () => resolve(result);
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            transaction.objectStore("caches").get(cacheId).onsuccess = function(): void {
                result = this.result;
            };
        });
    }

    public async purgeCache(cacheId: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let result = false;
            const transaction = this.db!.transaction(["assets", "assetsData", "caches"], "readwrite");
            transaction.oncomplete = () => resolve(result);
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            for (const storeName of ["assets", "assetsData"]) {
                const request = transaction.objectStore(storeName).index("cacheId").openCursor(IDBKeyRange.only(cacheId));
                request.onsuccess = () => { // eslint-disable-line no-loop-func
                    const cursor = request.result;
                    if (cursor !== null) {
                        cursor.delete();
                        cursor.continue();
                        result = true;
                    }
                };
            }

            transaction.objectStore("caches").delete(cacheId);
        });
    }

    public async getCaches(): Promise<Map<string, Cache<TCacheMetadta>>> {
        return new Promise((resolve, reject) => {
            const result = new Map<string, Cache<TCacheMetadta>>();

            const transaction = this.db!.transaction(["caches"]);
            transaction.oncomplete = () => resolve(result);
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            transaction.objectStore("caches").openCursor().onsuccess = function(): void {
                const cursor = this.result;
                if (cursor !== null) {
                    const cache = cursor.value as Cache<TCacheMetadta>;
                    result.set(cache.id, cache);
                    cursor.continue();
                }
            };
        });
    }

    public async purgeAll(): Promise<void> {
        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction(["assets", "assetsData", "caches"], "readwrite");
            transaction.oncomplete = () => resolve();
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            transaction.objectStore("assets").clear();
            transaction.objectStore("assetsData").clear();
            transaction.objectStore("caches").clear();
        });
    }

    public async storeAsset(cacheId: string, asset: CacheAsset<TAssetMetadta>): Promise<void> {
        return new Promise((resolve, reject) => {
            const assetWithoutData = { ...asset, cacheId: cacheId };
            delete assetWithoutData.data;

            const transaction = this.db!.transaction(["assets", "assetsData", "caches"], "readwrite");
            transaction.oncomplete = () => resolve();
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            transaction.objectStore("caches").get(cacheId).onsuccess = function() {
                const cache = this.result as Cache<TCacheMetadta> | undefined;
                if (cache === undefined) {
                    reject(new Error("Cache doesn't exists"));
                    return;
                }

                transaction.objectStore("assetsData").get([asset.id, cacheId]).onsuccess = function() {
                    if (this.result !== undefined && this.result.data !== undefined) {
                        cache.size -= (this.result.data instanceof ArrayBuffer) ? this.result.data.byteLength : this.result.data.length;
                    }

                    if (asset.data !== undefined) {
                        cache.size += (asset.data instanceof ArrayBuffer) ? asset.data.byteLength : (asset.data as unknown as string).length;
                    }

                    transaction.objectStore("caches").put(cache);
                    transaction.objectStore("assets").put(assetWithoutData);
                    transaction.objectStore("assetsData").put({
                        cacheId: cacheId,
                        data: asset.data,
                        id: asset.id,
                    });
                };
            };
        });
    }

    public async getAsset(cacheId: string, assetId: string, peekOnly = false): Promise<CacheAsset<TAssetMetadta> | undefined> {
        return new Promise((resolve, reject) => {
            let asset: CacheAsset<TAssetMetadta> | undefined = undefined;
            let data: ArrayBuffer | undefined = undefined;

            const transaction = this.db!.transaction(["assets", "assetsData", "caches"], "readwrite");
            transaction.oncomplete = () => {
                if (asset !== undefined) {
                    asset.data = data!;
                }
                resolve(asset);
            };
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            transaction.objectStore("assets").get([assetId, cacheId]).onsuccess = function() {
                asset = this.result;

                if (asset === undefined || peekOnly) {
                    return;
                }

                transaction.objectStore("caches").get(cacheId).onsuccess = function() {
                    const cache = this.result as Cache<TCacheMetadta>;
                    cache.lastAccessed = Date.now();
                    transaction.objectStore("caches").put(cache);
                };
            };

            transaction.objectStore("assetsData").get([assetId, cacheId]).onsuccess = function() {
                if (this.result !== undefined) {
                    data = this.result.data;
                }
            };
        });
    }

    public async getAssets(cacheId: string): Promise<IterableIterator<CacheAsset<TAssetMetadta>>> {
        return new Promise((resolve, reject) => {
            const result: CacheAsset<TAssetMetadta>[] = [];

            const transaction = this.db!.transaction(["assets"]);
            transaction.oncomplete = () => resolve(result.values());
            transaction.onerror = event => reject(event);
            transaction.onabort = event => reject(event);

            transaction.objectStore("assets").index("cacheId").openCursor(IDBKeyRange.only(cacheId)).onsuccess = function() {
                const cursor = this.result;
                if (cursor !== null) {
                    result.push(cursor.value);
                    cursor.continue();
                }
            };
        });
    }

    public async destroy(): Promise<void> {
        if (this.db !== undefined) {
            this.db.close();
            this.db = undefined;
        }
    }
}
