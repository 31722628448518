import { CacheAsset, CacheDriver } from "./types";
import { Asset, AssetsStorage } from "p2p-media-loader-shaka";
import { CacheMetadata } from "./CacheMetadata";

export interface AssetMetadata {
    masterSwarmId: string;
    masterManifestUri: string;
    requestUri: string;
    requestRange?: string;
    responseUri: string;
}

export class AssetsStorageAdapter implements AssetsStorage {
    public constructor(private readonly cacheDriver: CacheDriver<CacheMetadata, AssetMetadata>) {}

    public async storeAsset(asset: Asset): Promise<void> {
        const cacheId = asset.masterSwarmId;

        if ((await this.cacheDriver.getCache(cacheId)) === undefined) {
            // create cache
            const cacheMetadata = {
                masterManifestUri: asset.masterManifestUri,
            };
            await this.cacheDriver.createCache(cacheId, cacheMetadata);
        }

        const assetWithoutData = { ...asset };
        delete assetWithoutData.data;

        const cacheAsset: CacheAsset<AssetMetadata> = {
            data: asset.data,
            id: this.getAssetId(asset.requestUri, asset.requestRange),
            metadata: assetWithoutData,
        };

        await this.cacheDriver.storeAsset(cacheId, cacheAsset);
    }

    public async getAsset(requestUri: string, requestRange: string | undefined, masterSwarmId: string): Promise<Asset | undefined> {
        const asset = await this.cacheDriver.getAsset(masterSwarmId, this.getAssetId(requestUri, requestRange), false);

        if (asset === undefined) {
            return undefined;
        }

        return { ...(asset.metadata), data: asset.data };
    }

    public async destroy(): Promise<void> {}

    private getAssetId(requestUri: string, requestRange?: string): string {
        return requestUri + (requestRange === undefined ? "" : requestRange);
    }
}
