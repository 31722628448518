import { VideoPlayerApp } from "@screencloud/video-player-sdk";
import { CacheDriver, Cache, StorageInfo } from "../cache-drivers/types";
import { IdbCacheDriver } from "../cache-drivers/IdbCacheDriver";
import { AssetMetadata } from "../cache-drivers/AssetsStorageAdapter";
import { Segment } from "p2p-media-loader-core";

interface CacheMetadata {
    masterManifestUri: string;
}

export class CacheManager {
    public readonly drivers = new Map<string, CacheDriver<CacheMetadata, Segment | AssetMetadata>>();

    public constructor(app: VideoPlayerApp) {
        app.onGetCacheDrivers(async () => Array.from(this.drivers.keys()));
        app.onGetCaches(async payload => Array.from((await this.getCaches(payload.driverId)).values()));
        app.onGetCache(async payload => this.getCache(payload.driverId, payload.cacheId));
        app.onGetStorageInfo(async payload => this.getStorageInfo(payload.driverId));
        app.onPurgeCache(async payload => this.purgeCache(payload.driverId, payload.cacheId));
        app.onPurgeAll(async payload => this.purgeAll(payload.driverId));
    }

    public async init(): Promise<void> {
        const idbCacheDriver = new IdbCacheDriver<CacheMetadata, Segment | AssetMetadata>();
        await idbCacheDriver.init();
        this.drivers.set(idbCacheDriver.id, idbCacheDriver);
    }

    public async getCaches(driverId: string): Promise<Map<string, Cache<CacheMetadata>>> {
        return this.getDriverOrThrow(driverId).getCaches();
    }

    public async getCache(driverId: string, cacheId: string): Promise<Cache<CacheMetadata> | null> {
        const cache = await this.getDriverOrThrow(driverId).getCache(cacheId);
        return (cache === undefined) ? null : cache;
    }

    public async getStorageInfo(driverId: string): Promise<StorageInfo> {
        return this.getDriverOrThrow(driverId).getStorageInfo();
    }

    public async purgeCache(driverId: string, cacheId: string): Promise<boolean> {
        return this.getDriverOrThrow(driverId).purgeCache(cacheId);
    }

    public async purgeAll(driverId: string): Promise<null> {
        await this.getDriverOrThrow(driverId).purgeAll();
        return null;
    }

    public async destroy(): Promise<void> {
        const destroyPromises = new Array<Promise<void>>();

        for (const driver of this.drivers.values()) {
            destroyPromises.push(driver.destroy());
        }

        await Promise.all(destroyPromises);
    }

    private getDriverOrThrow(driverId: string): CacheDriver<CacheMetadata, Segment | AssetMetadata> {
        const driver = this.drivers.get(driverId);
        if (driver === undefined) {
            throw new Error("No such cache driver");
        }

        return driver;
    }
}
