import { registerServiceWorker } from "./serviceworker-utils";
import { App } from "./player/App";
import { VideoPlayerApp } from "@screencloud/video-player-sdk";

window.addEventListener("load", async () => { // eslint-disable-line @typescript-eslint/no-misused-promises
    try {
        const registerPromise = registerServiceWorker();

        const videoElement = document.querySelector<HTMLVideoElement>("#root video");
        if (videoElement === null) {
            throw new Error("Can't find <video> element");
        }

        const app = new App(videoElement, new VideoPlayerApp());
        await Promise.all([app.run(), registerPromise]);
    } catch (e) {
        // FIXME: implement logging?
        console.log("Error creating app", e); // eslint-disable-line no-console
    }
});
