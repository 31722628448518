export async function registerServiceWorker(): Promise<void> {
    if ("serviceWorker" in window.navigator) {
        await navigator.serviceWorker.register("serviceworker.js");
    }
}

export async function unregisterAllSericeWorkers(): Promise<void> {
    if ("serviceWorker" in window.navigator) {
        const registrations = await window.navigator.serviceWorker.getRegistrations();
        await Promise.all(registrations.map(async registration => registration.unregister()));
    }
}
