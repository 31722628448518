import { CacheAsset, CacheDriver } from "./types";
import { Segment, SegmentsStorage } from "p2p-media-loader-core";
import { CacheMetadata } from "./CacheMetadata";

export class SegmentsStorageAdapter implements SegmentsStorage {
    public constructor(private readonly cacheDriver: CacheDriver<CacheMetadata, Segment>) {}

    public async storeSegment(segment: Segment): Promise<void> {
        const cacheId = segment.masterSwarmId;

        if ((await this.cacheDriver.getCache(cacheId)) === undefined) {
            // create cache
            const cacheMetadata = {
                masterManifestUri: segment.masterManifestUri,
            };
            await this.cacheDriver.createCache(cacheId, cacheMetadata);
        }

        const segmentWithoutData = { ...segment };
        delete segmentWithoutData.data;

        const asset: CacheAsset<Segment> = {
            data: segment.data!,
            id: segment.id,
            metadata: segmentWithoutData,
        };

        await this.cacheDriver.storeAsset(cacheId, asset);
    }

    public async getSegmentsMap(masterSwarmId: string): Promise<Map<string, {segment: Segment}>> {
        const assets = await this.cacheDriver.getAssets(masterSwarmId);
        const map = new Map<string, {segment: Segment}>();
        for (const asset of assets) {
            // TODO: filter assets from segments?
            map.set(asset.id, { segment: asset.metadata });
        }

        return map;
    }

    public async getSegment(id: string, masterSwarmId: string): Promise<Segment | undefined> {
        const asset = await this.cacheDriver.getAsset(masterSwarmId, id, false);

        if (asset === undefined) {
            return undefined;
        }

        asset.metadata.data = asset.data;
        return asset.metadata;
    }

    public async clean(): Promise<boolean> {
        return false;
    }

    public async destroy(): Promise<void> {}
}
