import { Engine, ShakaEngineSettings } from "p2p-media-loader-shaka";
import { Events, Segment } from "p2p-media-loader-core";
import { VideoPlayerApp, VideoPlayerAppToHostMessages } from "@screencloud/video-player-sdk";
import { CacheManager } from "./CacheManager";
import { SegmentsStorageAdapter } from "../cache-drivers/SegmentsStorageAdapter";
import { AssetsStorageAdapter, AssetMetadata } from "../cache-drivers/AssetsStorageAdapter";
import { CacheDriver } from "../cache-drivers/types";
import { CacheMetadata } from "../cache-drivers/CacheMetadata";

declare namespace shaka { // eslint-disable-line @typescript-eslint/no-namespace

    // TODO: remove next eslint-disable once issue fixed:
    // https://github.com/typescript-eslint/typescript-eslint/issues/937

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    class Player {
        public constructor();
        public destroy(): Promise<void>;
        public getMediaElement(): HTMLMediaElement | null;
        public load(manifestUri: string, startTime?: number): Promise<void>;
        public attach(element: HTMLMediaElement): Promise<void>;
        public configure(config: unknown): void;
        public addEventListener(type: string, listener: ((event: unknown) => void) | null): void;
    }
}

function processError<T>(error: T): T | { message: string; name: string } {
    return (error instanceof Error ? { message: error.message, name: error.name } : error);
}

export class VideoPlayer {
    private shakaPlayer?: shaka.Player;
    private p2pEngine?: Engine;
    private readonly mediaElementListeners = new Map<string, EventListenerOrEventListenerObject>();

    public constructor(
        private readonly htmlMediaElement: HTMLVideoElement,
        private readonly app: VideoPlayerApp,
        private readonly cacheManager: CacheManager
    ) {
        this.app.onLoad(async (payload) => {
            try {
                return await this.load(payload.manifestUri, payload.cacheDriverId, payload.p2pSettings, payload.startTime);
            } catch (e) {
                throw processError(e);
            }
        });
        this.app.onSetMuted(payload => this.setMuted(payload.isMuted));
        this.app.onPlay(async () => {
            try {
                return await this.play();
            } catch (e) {
                throw processError(e);
            }
        });
        this.app.onPause(() => this.pause());

        this.addMediaElementEventListener("playing", () => this.app.emitPlaying());
        this.addMediaElementEventListener("pause", () => this.app.emitPause());
        this.addMediaElementEventListener("ended", () => this.app.emitEnded());
        this.addMediaElementEventListener("play", () => this.app.emitPlay());
        this.addMediaElementEventListener("waiting", () => this.app.emitWaiting());
        this.addMediaElementEventListener("error", event => this.app.emitMediaElementError({ event: event }));
    }

    // eslint-disable-next-line max-lines-per-function, max-statements
    public async load(
        manifestUri: string, cacheDriverId?: string,
        p2pSettings: Partial<ShakaEngineSettings> = {},
        startTime: number | undefined = undefined
    ): Promise<null> {
        if (this.shakaPlayer !== undefined) {
            await this.shakaPlayer.destroy();
        }

        if (this.p2pEngine !== undefined) {
            await this.p2pEngine.destroy();
        }

        this.shakaPlayer = new shaka.Player();
        await this.shakaPlayer.attach(this.htmlMediaElement);

        this.shakaPlayer.configure({ abr: { enabled: false } });

        this.shakaPlayer.addEventListener("error", (event) => {
            this.app.emitError((event as ({ detail: VideoPlayerAppToHostMessages.Error.Payload })).detail);
        });

        if (cacheDriverId !== undefined) {
            const driver = this.cacheManager.drivers.get(cacheDriverId);
            if (driver === undefined) {
                throw new Error("No such cache driver");
            }

            const segmentsStorage = new SegmentsStorageAdapter(driver as CacheDriver<CacheMetadata, Segment>);
            const assetsStorage = new AssetsStorageAdapter(driver as CacheDriver<CacheMetadata, AssetMetadata>);

            if (p2pSettings.loader === undefined) {
                p2pSettings.loader = {};
            }

            if (p2pSettings.segments === undefined) {
                p2pSettings.segments = {};
            }

            p2pSettings.loader.segmentsStorage = segmentsStorage;
            p2pSettings.segments.assetsStorage = assetsStorage;
        }

        this.p2pEngine = new Engine(p2pSettings);

        this.p2pEngine.on(Events.PeerConnect, (peer: { id: string; remoteAddress?: string }) => this.app.emitPeerConnect({
            peerId: peer.id,
            remoteAddress: peer.remoteAddress,
        }));

        this.p2pEngine.on(Events.PeerClose, (peerId: string) => this.app.emitPeerClose({
            peerId: peerId,
        }));

        this.p2pEngine.on(Events.SegmentLoaded, (segment: Segment, peerId?: string) => this.app.emitSegmentLoaded({
            url: segment.url,
            peerId: peerId,
        }));

        this.p2pEngine.initShakaPlayer(this.shakaPlayer);

        await this.shakaPlayer.load(manifestUri, startTime);
        this.app.emitLoaded();
        return null;
    }

    public async play(): Promise<null> {
        if (this.shakaPlayer === undefined) {
            throw new Error("Video isn't loaded");
        }

        await this.htmlMediaElement.play();
        return null;
    }

    public pause(): void {
        if (this.shakaPlayer === undefined) {
            throw new Error("Video isn't loaded");
        }

        this.htmlMediaElement.pause();
    }

    public setMuted(isMuted: boolean): void {
        this.htmlMediaElement.muted = isMuted;
    }

    public async destroy(): Promise<void> {
        if (this.shakaPlayer !== undefined) {
            await this.shakaPlayer.destroy();
        }

        if (this.p2pEngine !== undefined) {
            await this.p2pEngine.destroy();
        }

        for (const [eventType, listener] of this.mediaElementListeners) {
            this.htmlMediaElement.removeEventListener(eventType, listener);
        }
    }

    private addMediaElementEventListener(eventType: string, listener: EventListenerOrEventListenerObject): void {
        this.htmlMediaElement.addEventListener(eventType, listener);
        this.mediaElementListeners.set(eventType, listener);
    }
}
